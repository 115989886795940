<template>
  <header class="header">
    <div class="header-content">
        <h1 class="site-name">
          <router-link to="/">
          <p>Immobilien</p>
          <p>Rentabilitätsrechner</p>    
      </router-link>
        </h1>
      <p class="site-description">Berechne und vergleiche Kennzahlen zu Immobilien-Investition</p>
    </div>
    <nav class="header-nav">
      <router-link 
        v-for="page in menuItems" 
        :key="page.path"
        :to="{ path: page.path, hash: page.hash }"
        custom
        v-slot="{ navigate, isActive }"
      >
        <button 
          @click="navigate"
          :class="{ active: isActive }"
        >
          {{ page.title }}
        </button>
      </router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      menuItems: [
        { path: '/einnahmen', hash: '#description' , title: 'Einnahmen' },
        { path: '/kosten', hash: '#description' , title: 'Kosten' },
        { path: '/gewinn', hash: '#description' , title: 'Gewinn' },
        { path: '/steuer', hash: '#description', title: 'Steuerlich relevante Kosten' },
        { path: '/kreditrate', hash: '#description', title: 'Kreditrate' },
        { path: '/bruttorendite', hash: '#description' , title: 'Bruttorendite' },
        { path: '/eigenkapitalrendite', hash: '#description', title: 'Eigenkapitalrendite' },
      ]
    }
  }
}
</script>

<style scoped>


.header-content {
  background-color: #174EA6;
  color: #FFFFFF;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.site-name {
  font-size: 1.7rem;
  margin: 0;
}

.site-description {
  font-size: 1rem;
  margin: 0;
  text-align: right;
  max-width: 50%;
}

a{
    text-decoration: none;
    color: inherit;
}

.header-nav {
  background-color: #D2E3FC;
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.header-nav button {
  padding: 10px 15px;
  border: none;
  background-color: transparent;
  //color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.header-nav button.active {
  background-color: #174EA6;
  color: #ffffff;
}

.header-nav button:hover {
  background-color: #174EA6;
  color: #ffffff;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .site-description {
    text-align: left;
    max-width: 100%;
    margin-top: 0.5rem;
  }

  .header-nav {
    flex-direction: column;
    gap: 5px;
  }
  
  .header-nav button {
    width: 100%;
    text-align: left;
  }
}
</style>
