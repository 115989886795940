<!-- src/views/ImpressumText.vue -->
<template>
  <div class="impressum">
    <h1>Impressum</h1>
    
    <section>
      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Daniel Bovan<br>
        Preußenstraße 11b<br>
        80809 München
      </p>
    </section>

    <section>
      <h2>Kontakt</h2>
      <p>
        E-Mail: DBovan@web.de
      </p>
    </section>
    <!--
    <section>
      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br>
        [Ihre Umsatzsteuer-ID]
      </p>
    </section>
    -->
    <section>
      <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
      <p>
        Daniel Bovan<br>
        Preußenstraße 11b<br>
        80809 München
      </p>
    </section>

    <!-- Weitere Abschnitte nach Bedarf -->
  </div>
</template>

<script>
export default {
  name: 'impressum-text',
}
</script>

<style scoped>
.impressum {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}
h1, h2 {
  color: #333;
}
section {
  margin-bottom: 30px;
}
</style>
