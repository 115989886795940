import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Datenschutz from './views/Datenschutz.vue'
import Impressum from './views/Impressum.vue'
import ROEExplanation from './components/ROEExplanation.vue'
import CashFlowExplanation from './components/CashFlowExplanation.vue'
import LoanPaymentExplanation from './components/LoanPaymentExplanation.vue'
import GrossYieldExplanation from './components/GrossYieldExplanation.vue'
import RentalIncomeExplanation from './components/RentalIncomeExplanation.vue'
import TaxDeductibleExplanation from './components/TaxDeductibleExplanation.vue'
import CostExplanation from './components/CostExplanation.vue'



const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('./components/HomeDefault.vue')
      },
      {
        path: '/eigenkapitalrendite',
        name: 'Eigenkapitalrendite',
        component: ROEExplanation
      },
      {
        path: '/gewinn',
        name: 'Gewinn',
        component: CashFlowExplanation
      },
      {
        path: '/kreditrate',
        name: 'Kreditrate',
        component: LoanPaymentExplanation
      },
      {
        path: '/bruttorendite',
        name: 'Bruttorendite',
        component: GrossYieldExplanation
      },
      {
        path: '/einnahmen',
        name: 'Einnahmen',
        component: RentalIncomeExplanation
      },
      {
        path: '/steuer',
        name: 'Steuer',
        component: TaxDeductibleExplanation
      },
      {
        path: '/kosten',
        name: 'Kosten',
        component: CostExplanation
      }
    ]
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // Use a smooth scroll to the element that matches the hash in the URL
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            window.scrollTo({
              top: element.offsetTop,
              behavior: 'smooth'
            });
          }
          resolve();
        }, 100);  // Delay to wait for the page content to load, if necessary
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };  // Scroll to top for non-hash routes
    }
  }
})

export default router