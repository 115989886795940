<template>
  <div class="roe-short-explanation">
    <h2>Rendite Rechner für Immobilien: Rentabilität und Mietrendite berechnen</h2>
    <p>
      Unser kostenloser Renditerechner für Immobilien ermöglicht Investoren eine Analyse ihrer Kapitalanlage. 
      Diese umfassende Rentabilitätsberechnung Immobilie unterstützt Sie dabei, eine Immobilie als Investment zu 
      bewerten und Ihre Immobilien Investment Strategie zu optimieren.
      Der Rechner berücksichtigt alle wichtigen Faktoren für Ihre Wirtschaftlichkeitsberechnungen.
      Ob Sie in Gewerbeimmobilien, Ferienimmobilien oder klassische 
      Anlageimmobilien investieren möchten - unsere Immobilienanalyse Tool hilft Ihnen, die Rentabilität 
      der Immobilie berechnen zu können.
    </p>
  </div>
</template>

<script>
export default {
  name: 'RoeShortExplanation'
}
</script>

<style scoped>
.roe-short-explanation {
  line-height: 1.6;
}

h2 {
  color: #2c3e50;
  margin-bottom: 0.5em;
  font-size: 1.5em;
}

p {
  margin-bottom: 1.5em;
}

@media (max-width: 768px) {
  .roe-short-explanation {
    padding: 0.5rem;
  }
}
</style>