<template>
  <footer class="footer">
    <nav>
      <router-link to="/impressum">Impressum</router-link>
      <router-link to="/datenschutz">Datenschutz</router-link>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>

<style scoped>
.footer {
  background-color: #D2E3FC;
  padding: 1rem;
}

nav {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
  color: #333;
  margin: 0 1rem;
  padding: 0.5rem;
}

a:hover {
  text-decoration: underline;
}

/* Styles for active router link */
a.router-link-active {
  font-weight: bold;
}
</style>
