<template>
  <div class="home-container" @click="handleContainerClick">
    <ShortExplanation />
    <ROECalculator 
      :selectedCalculation="selectedCalculation"
      @calculation-made="addCalculation"
      @calculation-updated="updateCalculation"
      @edit-cancelled="clearSelectedCalculation"
    />
    <ROETable 
      :calculations="calculations"
      :selectedRowIndex="selectedRowIndex"
      @row-selected="handleRowSelected"
      @selection-cleared="clearSelectedCalculation"
      @row-deleted="deleteCalculation"
    />
    <router-view></router-view>
    
    <!-- Scroll to Top Button -->
    <button 
      class="scroll-to-top" 
      @click="scrollToTop"
      v-show="showScrollButton"
    >
      ↑
    </button>
  </div>
</template>

<script>
import ShortExplanation from '../components/ShortExplanation.vue'
import ROECalculator from '../components/ROECalculator.vue'
import ROETable from '../components/ROETable.vue'

export default {
  name: 'App',
  components: {
    ShortExplanation,
    ROECalculator,
    ROETable,
  },
  data() {
    return {
      calculations: [],
      selectedCalculation: null,
      selectedRowIndex: -1,
      showScrollButton: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    addCalculation(calculation) {
      this.calculations.push(calculation);
    },
    updateCalculation(updatedCalculation) {
      const index = this.calculations.findIndex(calc => calc === this.selectedCalculation);
      if (index !== -1) {
        this.calculations[index] = updatedCalculation;
        this.clearSelectedCalculation();
      }
    },
    deleteCalculation(index) {
      this.calculations.splice(index, 1);
      this.clearSelectedCalculation();
    },
    handleRowSelected(calculation) {
      this.selectedCalculation = calculation;
      this.selectedRowIndex = this.calculations.indexOf(calculation);
    },
    clearSelectedCalculation() {
      this.selectedCalculation = null;
      this.selectedRowIndex = -1;
    },
    handleContainerClick(event) {
      // Only clear if clicking directly on the container, not its children
      if (event.target === event.currentTarget) {
        this.clearSelectedCalculation();
      }
    },
    // New methods for scroll button
    handleScroll() {
      this.showScrollButton = window.scrollY > 300;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
.home-container {
  min-height: 100vh;
  padding: 20px;
}

/deep/ div {
  line-height: 1.6;
}

/* ... (keep existing styles) ... */

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  background-color: #174EA6;
  color: #FFFFFF;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.scroll-to-top:hover {
  background-color: #1A237E;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .roe-explanation {
    padding: 0.5rem;
  }
  
  h1 {
    font-size: 1.75em;
  }
  
  h2 {
    font-size: 1.25em;
  }

  .scroll-to-top {
    bottom: 15px;
    left: 15px;
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}
</style>