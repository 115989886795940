<template>
  <div id="description" class="cashflow-description">
    <h2>Gewinn bei Immobilieninvestitionen: Ein entscheidender Faktor für den Erfolg</h2>
    
    <p>
      Der <strong>Gewinn</strong> spiegelt den tatsächlichen Geldfluss wider, der durch eine Immobilie generiert wird. 
      Einfach ausgedrückt: Der Gewinn ist die Differenz zwischen den Einnahmen und Ausgaben einer Immobilie über einen bestimmten Zeitraum.
    </p>

    <h3>Wie wird der Gewinn berechnet?</h3>
    
    <p>Die Berechnung des Gewinns erfolgt anhand dieser Formel:</p>
    
    <p class="formula">Jährlicher Gewinn = Jährliche Mieteinnahmen - Betriebskosten - Jährliche Darlehenszahlungen</p>
    
    <p>In unserem Berechnungsmodell verwenden wir folgende Formel:</p>
    
    <p class="formula">
        Jährlicher Gewinn = (Monatliche Miete * 12 * (1 - Leerstandsrate / 100))<br>
                        - Jährliche Betriebskosten<br>
                        - (Monatliche Darlehenszahlung * 12)
    </p>
    
    <h3>Was macht einen guten Gewinn aus?</h3>
    
    <p>
      Ein positiver Gewinn bedeutet, dass die Einnahmen die Ausgaben übersteigen. 
      Das ist das Ziel jedes Immobilieninvestors. 
      Ein guter Gewinn sollte nicht nur die laufenden Kosten decken, sondern auch einen Puffer für unerwartete Ausgaben und idealerweise einen Gewinn bieten.
    </p>
    
    <p>
      Als Faustregel gilt: Ein jährlicher Gewinn von 5-10% des Immobilienwertes wird oft als attraktiv angesehen. 
      Allerdings hängt die Definition eines "guten" Gewinns von verschiedenen Faktoren ab, wie dem lokalen Immobilienmarkt, der Finanzierungsstruktur und den individuellen Zielen des Investors.
    </p>

    <h3>Woran erkennt man einen schlechten Gewinn?</h3>
    
    <p>
      Ein negativer Gewinn bedeutet, dass die Ausgaben die Einnahmen übersteigen. 
      Dies kann kurzfristig akzeptabel sein, etwa bei Renovierungsarbeiten zur Wertsteigerung. 
      Langfristig sollte der Gewinn jedoch positiv sein, um eine nachhaltige Investition zu gewährleisten.
    </p>
    
    <p>Warnsignale für einen problematischen Gewinn sind:</p>
    <ul>
      <li>Regelmäßige Zuschüsse aus eigener Tasche</li>
      <li>Unfähigkeit, Rücklagen zu bilden</li>
      <li>Schwierigkeiten bei der Bedienung von Krediten</li>
    </ul>

    <h3>Vergleich mit anderen Kennzahlen</h3>
    
    <p>Der Gewinn ist eine wichtige, aber nicht die einzige Kennzahl für Immobilieninvestitionen. Andere relevante Werte sind:</p>
    
    <ul>
      <li>Eigenkapitalrendite (Return on Equity, ROE): Misst den Gewinn im Verhältnis zum eingesetzten Eigenkapital</li>
      <li>Bruttorendite: Zeigt das Verhältnis der Jahresmiete zum Kaufpreis</li>
      <li>Nettomietrendite: Berücksichtigt zusätzlich die Betriebskosten</li>
    </ul>
    
    <p>Der Gewinn sollte immer im Kontext dieser anderen Kennzahlen betrachtet werden, um ein ganzheitliches Bild der Investition zu erhalten.</p>

    <h3>Beispielberechnung</h3>
    
    <p>Angenommen, wir haben folgende Werte für eine Immobilieninvestition:</p>
    <ul>
      <li>Kaufpreis: 300.000 €</li>
      <li>Monatliche Miete: 1.500 €</li>
      <li>Jährliche Betriebskosten: 3.000 €</li>
      <li>Monatliche Darlehenszahlung: 1.000 €</li>
    </ul>
    
    <p>Der jährliche Gewinn würde sich wie folgt berechnen:</p>
    <p>Jährlicher Gewinn = (1.500 € * 12) - 3.000 € - (1.000 € * 12)
                    = 18.000 € - 3.000 € - 12.000 €
                    = 3.000 €</p>
    
    <p>In diesem Beispiel hätten wir einen positiven jährlichen Gewinn von 3.000 €, was auf eine solide Investition hindeutet.</p>

    <p>
      Bedenken Sie, dass jede Immobilieninvestition einzigartig ist. 
      Eine sorgfältige Analyse des Gewinns, kombiniert mit anderen finanziellen Kennzahlen und einer gründlichen Marktanalyse, ist unerlässlich für eine fundierte Investitionsentscheidung.
    </p>
  </div>
</template>

<script>
export default {
  name: 'CashflowDescription',
  metaInfo: {
    title: 'Renditeberechnung für Immobilieninvestitionen - Rentabilität und Wirtschaftlichkeit',
    meta: [
      { name: 'description', content: 'Erfahren Sie, wie Sie den Gewinn bei Immobilieninvestitionen berechnen und welche Faktoren für eine gute Rendite entscheidend sind. Detaillierte Anleitung zur Rentabilitätsanalyse.' }
    ]
  }
}
</script>

<style scoped>
.cashflow-description {
  line-height: 1.6;
}

h2, h3 {
  color: #2c3e50;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
}

p {
  margin-bottom: 1em;
}

.formula {
  font-family: 'Roboto', serif;
  font-size: 1.2em;
  text-align: center;
  margin: 1em 0;
}

ul {
  margin-bottom: 1em;
  padding-left: 2em;
}

@media (max-width: 768px) {
  .cashflow-description {
    padding: 0.5rem;
  }
  
  h2 {
    font-size: 1.75em;
  }
  
  h3 {
    font-size: 1.25em;
  }
}
</style>