<!-- CookieBanner.vue -->
<template>
  <div v-if="!accepted" class="cookie-banner">
    <div class="cookie-content">
      <h2>Cookie-Einstellungen</h2>
      <p>
        Wir verwenden Cookies, um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten. 
        Hier können Sie Ihre Einstellungen verwalten. Detaillierte Informationen finden Sie in unserer 
        <router-link to="/datenschutz">Datenschutzerklärung</router-link>
      </p>
      <div class="cookie-options">
        <label>
          <input type="checkbox" v-model="necessaryCookies" disabled checked>
          Notwendige Cookies
        </label>
        <label>
          <input type="checkbox" v-model="optionalCookies">
          Optionale Cookies (Analyse, Marketing)
        </label>
      </div>
      <div class="button-group">
        <button @click="acceptSelected" class="btn-primary">Auswahl bestätigen</button>
        <button @click="acceptAll" class="btn-secondary">Alle akzeptieren</button>
        <button @click="showDetails = !showDetails" class="btn-tertiary">
          {{ showDetails ? 'Details ausblenden' : 'Details anzeigen' }}
        </button>
      </div>
    </div>
    <div v-if="showDetails" class="cookie-details">
      <h3>Details zu unseren Cookies:</h3>
      <ul>
        <li><strong>Notwendige Cookies:</strong> Diese Cookies sind für die Grundfunktionen der Website erforderlich.</li>
        <li><strong>Analyse-Cookies:</strong> Helfen uns zu verstehen, wie Besucher mit der Website interagieren.</li>
        <li><strong>Marketing-Cookies:</strong> Werden verwendet, um Werbung relevanter für Sie zu gestalten.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      accepted: false,
      necessaryCookies: true,
      optionalCookies: false,
      showDetails: false
    }
  },
  methods: {
    acceptSelected() {
      this.saveCookiePreferences(this.optionalCookies);
    },
    acceptAll() {
      this.saveCookiePreferences(true);
    },
    saveCookiePreferences(includeOptional) {
      const preferences = {
        necessary: true,
        optional: includeOptional
      };
      Cookies.set('cookiePreferences', JSON.stringify(preferences), { expires: 365 });
      this.accepted = true;
      this.$emit('preferences-updated', preferences);
    },
    loadPreferences() {
      const savedPreferences = Cookies.get('cookiePreferences');
      if (savedPreferences) {
        const preferences = JSON.parse(savedPreferences);
        this.accepted = true;
        this.optionalCookies = preferences.optional;
        this.$emit('preferences-updated', preferences);
      }
    }
  },
  mounted() {
    this.loadPreferences();
  }
}
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 -5px 15px rgba(0,0,0,0.1);
  border-top: 3px solid #174EA6;
  max-height: 80vh;
  overflow-y: auto;
}

.cookie-content {
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  margin-top: 0;
  color: #333;
}

p {
  color: #666;
  line-height: 1.6;
}

.cookie-options {
  margin: 15px 0;
}

.cookie-options label {
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

button {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #174EA6;
  color: white;
}

.btn-primary:hover {
  background-color: #14438F;
}

.btn-secondary {
  background-color: #f1f1f1;
  color: #333;
}

.btn-secondary:hover {
  background-color: #e0e0e0;
}

.btn-tertiary {
  background-color: transparent;
  color: #174EA6;
  text-decoration: underline;
}

.btn-tertiary:hover {
  color: #174EA6;
}

.cookie-details {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

.cookie-details h3 {
  color: #333;
}

.cookie-details ul {
  padding-left: 20px;
  color: #666;
}

@media (max-width: 600px) {
  .button-group {
    flex-direction: column;
  }
  
  button {
    width: 100%;
  }
}
</style>
