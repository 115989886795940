<!-- src/components/ROECalculator.vue -->
<template>
  <div class="roe-calculator">
    <form @submit.prevent="handleSubmit">
      <div class="form-row">
        <div class="form-group">
          <label for="purchasePrice">Kaufpreis (€):</label>
          <input id="purchasePrice" v-model.number="formData.purchasePrice" type="number" required>
        </div>
        <div class="form-group">
          <label for="downPayment">Eigenkapital (€):</label>
          <input id="downPayment" v-model.number="formData.downPayment" type="number" required>
        </div>
        <div class="form-group">
          <label for="additionalCosts">Kaufnebenkosten (€):</label>
          <input id="additionalCosts" v-model.number="formData.additionalCosts" type="number" required>
        </div>
        <div class="form-group">
          <label for="interestRate">Zinssatz (%):</label>
          <input id="interestRate" v-model.number="formData.interestRate" type="number" step="0.01" required>
        </div>
        <div class="form-group">
          <label for="repaymentRate">Tilgung (%):</label>
          <input id="repaymentRate" v-model.number="formData.repaymentRate" type="number" step="0.01" required>
        </div>
        <div class="form-group">
          <label for="loanTerm">Kredit Laufzeit:</label>
          <input id="loanTerm" v-model.number="formData.loanTerm" type="number" required>
        </div>
        <div class="form-group">
          <label for="monthlyRent">Monatliche Mieteinnahmen (€):</label>
          <input id="monthlyRent" v-model.number="formData.monthlyRent" type="number" required>
        </div>
        <div class="form-group">
          <label for="annualOperatingCosts">Jährliche Betriebskosten (€):</label>
          <input id="annualOperatingCosts" v-model.number="formData.annualOperatingCosts" type="number" required>
        </div>
        <div class="form-group">
          <label for="vacancyRate">Leerstandsrate (%):</label>
          <input id="vacancyRate" v-model.number="formData.vacancyRate" type="number" step="0.1" required>
        </div>
        <div class="form-group">
          <label for="appreciationRate">Jährliche Wertsteigerungsrate (%):</label>
          <input id="appreciationRate" v-model.number="formData.appreciationRate" type="number" step="0.1" required>
        </div>
      </div>
      <div class="button-error-container">
        <div v-if="errorMessage" class="error">
          <p>{{ errorMessage }}</p>
        </div>
        <button type="submit">{{ isUpdateMode ? 'Aktualisieren' : 'Kennzahlen berechnen' }}</button>
        <button v-if="isUpdateMode" type="button" @click="cancelEdit">Abbrechen</button>
      </div>
    </form>
  </div>
</template>

<script>
import { calculateBasicInvestmentMetrics } from '../services/realEstateInvestmentCalculator';

export default {
  emits: ['calculation-made', 'calculation-updated', 'edit-cancelled'],
  props: {
    selectedCalculation: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formData: {
        purchasePrice: 0,
        downPayment: 0,
        additionalCosts: 0,
        interestRate: 0,
        repaymentRate: 0,
        monthlyRent: 0,
        annualOperatingCosts: 0,
        vacancyRate: 0,
        appreciationRate: 0,
      },
      result: null,
      errorMessage: ''
    };
  },
  computed: {
    isUpdateMode() {
      return this.selectedCalculation !== null;
    }
  },
  watch: {
    selectedCalculation: {
      handler(newValue) {
        if (newValue) {
          this.formData = { ...newValue };
          this.result = {
            returnOnEquity: newValue.returnOnEquity
          };
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      const result = calculateBasicInvestmentMetrics(this.formData);

      if (isNaN(result.returnOnEquity) || !isFinite(result.returnOnEquity)) {
        this.errorMessage = 'Das ROE-Ergebnis ist ungültig (Nicht Berechenbar oder Unendlich). Bitte überprüfen Sie die Eingabewerte.';
        this.result = null;
      } else {
        this.errorMessage = '';
        this.result = result;

        const calculationData = {
          ...this.formData,
          ...result
        };

        if (this.isUpdateMode) {
          this.$emit('calculation-updated', calculationData);
        } else {
          this.$emit('calculation-made', calculationData);
        }
      }
    },
    cancelEdit() {
      this.$emit('edit-cancelled');
      this.resetForm();
    },
    resetForm() {
      this.formData = {
        purchasePrice: 0,
        downPayment: 0,
        additionalCosts: 0,
        interestRate: 0,
        repaymentRate: 0,
        loanTerm: 0,
        monthlyRent: 0,
        annualOperatingCosts: 0,
        vacancyRate: 0,
        appreciationRate: 0,
      };
      this.result = null;
      this.errorMessage = '';
    }
  }
};
</script>

<style scoped>
.returnOnEquity-calculator {
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.form-group {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
}

.button-error-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #174EA6;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 5px;
}

button:hover {
  background-color: #14438F;
}

.error {
  color: red;
  flex-grow: 1;
  text-align: right;
}

@media (max-width: 768px) {
  .form-group {
    flex: 1 1 100%;
  }

  input {
    width: 100%;
  }

  .button-error-container {
    flex-direction: column-reverse;
    align-items: stretch;
  }

  .error {
    text-align: left;
  }

  button {
    width: 100%;

  }
}
</style>
