<template>
    <div id="description" class="annual-costs-description">
      <h1>Jährliche Gesamtkosten & Rendite verstehen</h1>
      
      <p>
        Mit unserem Rechner analysieren Sie die wirtschaftliche Performance Ihrer Immobilieninvestition. 
        Die jährlichen Gesamtkosten sind ein Teil zur Berechnung der Immobilienrendite.
      </p>
      
      <h3>Zusammensetzung der jährlichen Kosten</h3>
      
      <p class="formula">
        Jährliche Gesamtkosten = Jährliche Betriebskosten + Jährliche Kreditkosten
      </p>
  
      <h2>Komponenten der jährlichen Kosten im Detail</h2>
      
      <ul>
        <li>Jährliche Betriebskosten
          <ul>
            <li>Instandhaltungskosten und Rücklagen</li>
            <li>Hausverwaltung und Property Management</li>
            <li>Versicherungen und Absicherung</li>
            <li>Grundsteuer und kommunale Abgaben</li>
            <li>Nicht umlagefähige Nebenkosten</li>
          </ul>
        </li>
        <li>Jährliche Finanzierungskosten für die Immobilien
          <ul>
            <li>12 × monatliche Kreditrate (Annuität)</li>
            <li>Zinsen und Tilgung im Detail</li>
          </ul>
        </li>
      </ul>
  
      <h3>Berücksichtigung im Cash-Flow</h3>
      
      <p>
        Die jährlichen Gesamtkosten werden von den Mieteinnahmen abgezogen, um den jährlichen Cash-Flow zu ermitteln:
      </p>
      
      <p class="formula">
        Jährlicher Cash-Flow = Jährliche Mieteinnahmen - Jährliche Gesamtkosten
      </p>
  
      <h2>Praxisbeispiel: Berechnung der jährlichen Kosten</h2>
          
      <div class="calculation-example">
        <p>Beispielrechnung für Vermögensaufbau mit Immobilien:</p>
        <ul>
          <li>Jährliche Betriebskosten: 4.800 €</li>
          <li>Monatliche Kreditrate: 1.200 € (Jährlich: 14.400 €)</li>
        </ul>
        
        <p>Berechnung der jährlichen Gesamtkosten:</p>
        <ul>
          <li>Betriebskosten pro Jahr: 4.800 €</li>
          <li>Kreditkosten pro Jahr: 14.400 €</li>
          <li>Jährliche Gesamtkosten: 19.200 €</li>
        </ul>
  
        <p>Bei jährlichen Mieteinnahmen von 24.000 € ergibt sich:</p>
        <ul>
          <li>Jährlicher Cash-Flow: 24.000 € - 19.200 € = 4.800 €</li>
        </ul>
      </div>
  
      <h2>Wichtige Aspekte bei der Kostenberechnung</h2>
      
      <ul>
        <li>Leerstandsrisiko: Kalkulation möglicher Mietausfälle</li>
        <li>Kostendynamik: Prognose künftiger Kostensteigerungen</li>
        <li>Instandhaltungsrücklage: Reserven für Modernisierung</li>
        <li>Steueroptimierung: Abschreibungen und Werbungskosten</li>
      </ul>
  
      <h3>Immobilien Portfolio aufbauen: Kostenoptimierung für maximale Rendite</h3>
      
      <p>
        Für ein erfolgreiches Geld in Immobilien anlegen sollten Sie regelmäßig folgende Faktoren optimieren:
      </p>
      
      <ul>
        <li>Betriebskosten durch Benchmarking minimieren</li>
        <li>Finanzierungsstruktur regelmäßig überprüfen</li>
        <li>Wartungsmanagement zur Wertsicherung</li>
        <li>Professionelles Property Management</li>
      </ul>
    </div>
</template>
  
<script>
export default {
  name: 'AnnualCostsDescription',
  metaInfo: {
    title: 'Rentabilität Immobilie berechnen | Online Renditerechner Immobilien kostenlos',
    meta: [
      { 
        name: 'description', 
        content: 'Immobilienrendite und Mietrendite berechnen ✓ Kostenloser Rentabilität Immobilie Rechner ✓ Wirtschaftlichkeitsanalyse für Ihre Immobilie als Investment ➤ Jetzt Rendite ermitteln!' 
      }
    ]
  }
}
</script>

<style scoped>
.annual-costs-description {
  line-height: 1.6;
}

h1, h2 {
  color: #2c3e50;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

p {
  margin-bottom: 1em;
}

.formula {
  font-family: 'Roboto', serif;
  font-size: 1.2em;
  text-align: center;
  margin: 1em 0;
  padding: 1em;
  background-color: #f8f9fa;
  border-radius: 4px;
}

ul {
  margin-bottom: 1em;
  padding-left: 2em;
}

.calculation-example {
  background-color: #f8f9fa;
  padding: 1.5em;
  border-radius: 8px;
  margin: 1em 0;
}

.calculation-example ul {
  list-style-type: none;
  padding-left: 1em;
}

@media (max-width: 768px) {
  .annual-costs-description {
    padding: 0.5rem;
  }
  
  h1 {
    font-size: 1.75em;
  }
  
  h2 {
    font-size: 1.25em;
  }
}
</style>