// src/components/ROEDetailView.vue
<template>
  <div v-if="selectedRow" class="roe-detail-view">
    <h3>Detaillierte Analyse über {{ jahreBisTilgung }} Jahre</h3>
    
    <div class="charts-container">
      <div class="chart">
        <h4>Eigenkapitalentwicklung</h4>
        <LineChart
          :data="equityChartData"
          :options="equityChartOptions"
        />
      </div>
      
      <div class="chart">
        <h4>Restschuld</h4>
        <LineChart
          :data="cashflowChartData"
          :options="cashflowChartOptions"
        />
      </div>
    </div>
    
    <div class="yearly-data-table">
      <table>
        <thead>
          <tr>
            <th>Jahr</th>
            <th>Immobilienwert</th>
            <th>Restschuld</th>
            <th>Eigenkapital</th>
            <th>Zinskosten</th>
            <th>Tilgung</th>
            <th>Steuerkosten</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in paginatedData" :key="data.year">
            <td>{{ data.year }}</td>
            <td>{{ formatCurrency(data.propertyValue) }}</td>
            <td>{{ formatCurrency(data.remainingDebt) }}</td>
            <td>{{ formatCurrency(data.equity) }}</td>
            <td>{{ formatCurrency(data.interest) }}</td>
            <td>{{ formatCurrency(data.repayment) }}</td>
            <td>{{ formatCurrency(data.taxDeductibleCosts) }}</td>
          </tr>
        </tbody>
      </table>
      
      <div class="pagination">
        <button 
          @click="prevPage" 
          :disabled="currentPage === 1"
          class="pagination-btn"
        >
          Vorherige
        </button>
        <span class="page-info">
          Seite {{ currentPage }} von {{ totalPages }}
        </span>
        <button 
          @click="nextPage" 
          :disabled="currentPage === totalPages"
          class="pagination-btn"
        >
          Nächste
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { Line as LineChart } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { calculateDetailedInvestmentMetrics } from '../services/realEstateInvestmentCalculator'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default {
  name: 'ROEDetailView',
  components: {
    LineChart
  },
  props: {
    selectedRow: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yearlyData = ref([])
    const currentPage = ref(1)
    const itemsPerPage = 10

    const calculateDetailedData = () => {
      if (!props.selectedRow) return

      yearlyData.value = calculateDetailedInvestmentMetrics({
        purchasePrice: props.selectedRow.purchasePrice,
        downPayment: props.selectedRow.downPayment,
        interestRate: props.selectedRow.interestRate,
        repaymentRate: props.selectedRow.repaymentRate,
        loanTerm: props.selectedRow.loanTerm,
        monthlyRent: props.selectedRow.monthlyRent,
        annualOperatingCosts: props.selectedRow.annualOperatingCosts,
        vacancyRate: props.selectedRow.vacancyRate ,
        appreciationRate: props.selectedRow.appreciationRate
      })
    }

    watch(() => props.selectedRow, calculateDetailedData, { immediate: true, deep: true })

    const totalPages = computed(() => Math.ceil(yearlyData.value.length / itemsPerPage))

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage
      const end = start + itemsPerPage
      return yearlyData.value.slice(start, end)
    })

    const prevPage = () => {
      if (currentPage.value > 1) currentPage.value--
    }

    const nextPage = () => {
      if (currentPage.value < totalPages.value) currentPage.value++
    }

    const equityChartData = computed(() => ({
      labels: yearlyData.value.map(d => d.year),
      datasets: [{
        label: 'Eigenkapital',
        data: yearlyData.value.map(d => d.equity),
        borderColor: '#36A2EB',
        fill: false
      }]
    }))

    const cashflowChartData = computed(() => ({
      labels: yearlyData.value.map(d => d.year),
      datasets: [{
        label: 'Restschuld',
        data: yearlyData.value.map(d => d.remainingDebt),
        borderColor: '#FF6384',
        fill: false
      }]
    }))

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false
    }

 return {
      jahreBisTilgung: computed(() => props.selectedRow.jahreBisTilgung),
      yearlyData,
      currentPage,
      totalPages,
      paginatedData,
      prevPage,
      nextPage,
      equityChartData,
      cashflowChartData,
      equityChartOptions: chartOptions,
      cashflowChartOptions: chartOptions,
      formatCurrency: (value) => `${value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €`,
      formatPercent: (value) => `${value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
    }
  }
}
</script>

<style scoped>
.roe-detail-view {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.charts-container {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.chart {
  flex: 1;
  height: 300px;
}

.yearly-data-table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

th, td {
  padding: 0.5rem;
  text-align: right;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #f5f5f5;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.pagination-btn {
  padding: 0.5rem 1rem;
  background-color: #174EA6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.page-info {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .charts-container {
    flex-direction: column;
  }
  
  .chart {
    width: 100%;
  }
}
</style>
