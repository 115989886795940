// src/services/realEstateInvestmentCalculator.js

/**
 * Calculates tax deductible costs according to German tax law
 * @param {number} purchasePrice - Total purchase price
 * @param {number} buildingValue - Value of the building (excluding land)
 * @returns {Object} Tax deductible costs
 */
function calculateTaxDeductibleCosts(yearlyInterest, buildingValue, annualOperatingCosts) {
  // Calculate yearly depreciation (2% of building value over 50 years)
  const yearlyDepreciation = buildingValue * 0.02;
  
  return yearlyDepreciation + yearlyInterest + annualOperatingCosts
}

function calculateMonthlyInterestRate(rate) {
  return ( (rate / 100) / 12 ) ;
}

/**
 * Calculates the monthly loan payment based on the loan amount, interest rate, term, and repayment rate
 * @param {number} loanAmount - Total loan amount in currency units
 * @param {number} monthlyInterestRate - monthly interest rate as a percentage
 * @param {number} loanTermYears - Loan term in years
 * @param {number} repaymentRate - Annual repayment rate as a percentage
 * @returns {number} Monthly payment amount
 */
function calculateMonthlyLoanPayment(loanAmount, monthlyInterestRate, monthlyRepaymentRate) {
  // Total monthly rate (interest + repayment)
  const monthlyRate = monthlyInterestRate + monthlyRepaymentRate;
  return loanAmount * calculateMonthlyInterestRate(monthlyRate) ;
}

/**
 * Calculates yearly loan data including interest, repayment, and remaining debt
 * @param {Object} params - Loan parameters
 * @returns {Array} Yearly loan data
 */
function calculateYearlyLoanData({
  loanAmount,
  interestRate,
  loanTermYears,
  repaymentRate
}) {
  const monthlyPayment = calculateMonthlyLoanPayment(
    loanAmount,
    interestRate,
    repaymentRate
  );

  const percentInterestRate = calculateMonthlyInterestRate(interestRate);
  let remainingLoan = loanAmount;
  let yearlyData = [];

  for (let year = 1; year <= loanTermYears; year++) {
    let yearlyInterest = 0;
    let yearlyRepayment = 0;

    for (let month = 1; month <= 12; month++) {
      const monthlyInterest = remainingLoan * percentInterestRate;
      const monthlyRepayment = monthlyPayment - monthlyInterest;

      yearlyInterest += monthlyInterest;
      yearlyRepayment += monthlyRepayment;
      remainingLoan -= monthlyRepayment;
    }

    yearlyData.push({
      year,
      yearlyInterest,
      yearlyRepayment,
      remainingLoan: Math.max(0, remainingLoan),
      monthlyPayment
    });
  }

  return yearlyData;
}

/**
 * Calculates annual financial metrics for the investment
 * @param {Object} params - Financial parameters
 * @returns {Object} Annual financial metrics
 */
function calculateAnnualFinancialMetrics({
  rentalIncome,
  operatingCosts,
  vacancyRate,
  monthlyLoanPayment
}) {
  const annualRentalIncome = rentalIncome * 12 * (1 - vacancyRate / 100);
  const annualLoanCosts = monthlyLoanPayment * 12;
  const annualCosts = operatingCosts + annualLoanCosts
  const annualCashFlow = annualRentalIncome - annualCosts;

  return {
    annualRentalIncome,
    annualCashFlow,
    annualCosts,
    annualLoanCosts
  };
}

/**
 * Calculates basic investment metrics for a real estate investment
 * @param {Object} params - Investment parameters
 * @returns {Object} Basic investment metrics
 */
export function calculateBasicInvestmentMetrics({
  purchasePrice,
  downPayment,
  additionalCosts,
  interestRate,
  repaymentRate,
  monthlyRent,
  annualOperatingCosts,
  vacancyRate,
  appreciationRate
}) {
  // Validate and normalize inputs
  const safePurchasePrice = Math.max(0, Number(purchasePrice) || 0);
  const safeAdditionalCosts = Math.max(0, Number(additionalCosts) || 0);
  const safeDownPayment = Math.max(0, Math.min(safePurchasePrice+safeAdditionalCosts, Number(downPayment) || 0));
  const safeAppreciationRate = Number(appreciationRate) || 0;
  const buildingValue = safePurchasePrice + safeAdditionalCosts
  const loanAmount = buildingValue - safeDownPayment;

  // Calculate loan payments
  const monthlyPayment = calculateMonthlyLoanPayment(
    loanAmount,
    interestRate,
    repaymentRate
  );

  // Calculate financial metrics
  const {
    annualRentalIncome,
    annualCashFlow,
    annualLoanCosts,
    annualCosts
  } = calculateAnnualFinancialMetrics({
    rentalIncome: monthlyRent,
    operatingCosts: annualOperatingCosts,
    vacancyRate,
    monthlyLoanPayment: monthlyPayment
  });

  // Calculate appreciation and returns
  const valueAfterOneYear = safePurchasePrice * (1 + safeAppreciationRate / 100);
  const appreciation = valueAfterOneYear - safePurchasePrice;
  const totalReturn = annualCashFlow + appreciation;

  // Calculate ROE and gross yield
  const returnOnEquity = safeDownPayment > 0 ? (totalReturn / safeDownPayment) * 100 : 0;
  const grossYield = safePurchasePrice > 0 ? (annualRentalIncome / safePurchasePrice) * 100 : 0;

  // Calculate tax deductible costs
  const taxDeductibleCosts = calculateTaxDeductibleCosts(
    annualLoanCosts,
    buildingValue,
    annualOperatingCosts
  );

  return {
    returnOnEquity: returnOnEquity.toFixed(2),
    annualRentalIncome: annualRentalIncome.toFixed(2),
    annualCashFlow: annualCashFlow.toFixed(2),
    monthlyLoanPayment: monthlyPayment.toFixed(2),
    grossYield: grossYield.toFixed(2),
    taxDeductibleCosts: taxDeductibleCosts,
    annualCosts: annualCosts
  };
}

/**
 * Calculates detailed yearly investment metrics for a real estate investment
 * @param {Object} params - Investment parameters
 * @returns {Array} Detailed yearly investment metrics
 */
export function calculateDetailedInvestmentMetrics({
  purchasePrice,
  downPayment,
  additionalCosts,
  interestRate,
  repaymentRate,
  loanTerm,
  monthlyRent,
  annualOperatingCosts,
  vacancyRate,
  appreciationRate
}) {
  const safePurchasePrice = Math.max(0, Number(purchasePrice) || 0);
  const safeAdditionalCosts = Math.max(0, Number(additionalCosts) || 0);
  const safeDownPayment = Math.max(0, Math.min(safePurchasePrice, Number(downPayment) || 0));
  const safeAppreciationRate = Number(appreciationRate) || 0;
  const buildingValue = safePurchasePrice + safeAdditionalCosts
  const loanAmount = buildingValue - safeDownPayment;

  const loanData = calculateYearlyLoanData({
    loanAmount,
    interestRate,
    loanTermYears: loanTerm,
    repaymentRate
  });

  let yearlyData = [];
  let currentPropertyValue = safePurchasePrice;

  for (let i = 0; i < loanData.length; i++) {
    const year = i + 1;
    const yearLoanData = loanData[i];

    const {
      annualCashFlow
    } = calculateAnnualFinancialMetrics({
      rentalIncome: monthlyRent,
      operatingCosts: annualOperatingCosts,
      vacancyRate,
      monthlyLoanPayment: yearLoanData.monthlyPayment
    });

    // Update property value based on appreciation
    currentPropertyValue *= (1 + safeAppreciationRate / 100);

    // Calculate equity and equity change
    const equity = currentPropertyValue - yearLoanData.remainingLoan;
    const prevEquity = year === 1 ? safeDownPayment : yearlyData[year - 2].equity;
    const equityChange = equity - prevEquity;

    // Calculate return on equity for the year
    const yearReturnOnEquity = prevEquity > 0 ? ((annualCashFlow + equityChange) / prevEquity) * 100 : 0;

    // Calculate tax deductible costs for the year
    const taxDeductibleCosts = calculateTaxDeductibleCosts(
      yearLoanData.yearlyInterest,
      buildingValue,
      annualOperatingCosts
    );

    yearlyData.push({
      year,
      propertyValue: currentPropertyValue,
      remainingDebt: yearLoanData.remainingLoan,
      equity,
      interest: yearLoanData.yearlyInterest,
      repayment: yearLoanData.yearlyRepayment,
      returnOnEquity: yearReturnOnEquity,
      taxDeductibleCosts: taxDeductibleCosts
    });
  }

  return yearlyData;
}
