<template>
  <DatenschutzText />
</template>

<script>
import DatenschutzText from '../components/DatenschutzText.vue'

export default {
  name: 'view-datenschutz',
  components: {
    DatenschutzText,
  }
}
</script>

<style />
