<!-- DatenschutzText.vue -->
<template>
  <div class="datenschutz">
    <h1>Datenschutzerklärung</h1>
    
    <section>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.</p>
    </section>

    <section>
      <h2>2. Datenerfassung auf unserer Website</h2>
      <h3>Welche Daten erfassen wir?</h3>
      <p>Wir erfassen derzeit nur die Daten, die Sie aktiv in Formularfelder auf unserer Website eingeben. In Zukunft planen wir, zusätzliche Nutzerdaten für Werbezwecke zu erheben.</p>
    </section>

    <section>
      <h2>3. Wie nutzen wir Ihre Daten?</h2>
      <p>Die von Ihnen eingegebenen Daten werden ausschließlich zur Bearbeitung Ihrer Anfragen und zur Bereitstellung der von Ihnen angeforderten Dienste verwendet.</p>
      <p>In Zukunft können Ihre Daten auch für personalisierte Werbung genutzt werden. Wir werden Sie über Änderungen in unserer Datennutzung informieren und gegebenenfalls Ihre Einwilligung einholen.</p>
    </section>

    <section>
      <h2>4. Ihre Rechte</h2>
      <p>Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung oder Löschung dieser Daten.</p>
    </section>

    <section>
      <h2>5. Analyse-Tools und Werbe-Tools</h2>
      <p>Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. In Zukunft planen wir, Werbe-Tools einzusetzen, um Ihnen personalisierte Werbung anzeigen zu können.</p>
    </section>

    <section>
      <h2>6. Kontakt</h2>
      <p>Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten wenden Sie sich bitte an:</p>
      <p>E-Mail: <a href="mailto:dbovan@web.de">DBovan@web.de</a></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'daten-schutz',
}
</script>

<style scoped>
.datenschutz {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}
h1, h2 {
  color: #333;
}
section {
  margin-bottom: 30px;
}
</style>
