<template>
  <ImpressumText />
</template>

<script>
import ImpressumText from '../components/ImpressumText.vue'

export default {
  name: 'view-impressum',
  components: {
    ImpressumText,
  }
}
</script>

<style />
