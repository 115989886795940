<template>
  <div class="roe-table-container">
    <div class="grid-wrapper">
      <ag-grid-vue
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="filteredData"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        domLayout="autoHeight"
        rowSelection="single"
        @row-selected="onRowSelected"
      />
    </div>
    
    <div class="table-actions" v-if="filteredData.length > 0">
      <button 
        class="delete-btn" 
        @click="deleteSelected"
        :disabled="!selectedRow"
      >
        Löschen
      </button>
    </div>

    <ROEDetailView 
      v-if="selectedRow"
      :selectedRow="selectedRow"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3'
import ROEDetailView from './ROEDetailView.vue'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

export default {
  components: {
    AgGridVue,
    ROEDetailView
  },
  props: {
    calculations: {
      type: Array,
      default: () => []
    },
    selectedRowIndex: {
      type: Number,
      default: -1
    }
  },
  emits: ['row-selected', 'selection-cleared', 'row-deleted'],
  data() {
    return {
      gridApi: null,
      selectedRow: null,
      columnDefs: [
        { 
          field: 'annualRentalIncome',
          headerName: 'Einnahmen',
          valueFormatter: params => `${params.value} €`,
        },
        {
          field: 'annualCosts',
          headerName: 'Kosten',
          valueFormatter: params => `${params.value} €`,
        },
        {
          field: 'annualCashFlow',
          headerName: 'Gewinn',
          valueFormatter: params => `${params.value} €`,
        },
        {
          field: 'taxDeductibleCosts',
          headerName: 'Steuerkosten',
          valueFormatter: params => `${params.value} €`,
        },
        {
          field: 'monthlyLoanPayment',
          headerName: 'Kreditrate',
          valueFormatter: params => `${params.value} €`,
        },
        {
          field: 'grossYield',
          headerName: 'Bruttorendite',
          valueFormatter: params => `${params.value} %`,
        },
        {
          field: 'returnOnEquity',
          headerName: 'Eigenkapitalrendite',
          valueFormatter: params => `${params.value} %`,
        }
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1,
      },
    }
  },
  computed: {
    filteredData() {
      return this.calculations.filter(item => 
        item &&
        !isNaN(item.grossYield) &&
        isFinite(item.grossYield) &&
        !isNaN(item.returnOnEquity) &&
        isFinite(item.returnOnEquity)
      )
    }
  },
  watch: {
    selectedRowIndex(newVal) {
      if (newVal === -1) {
        this.clearSelection();
      }
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      
      window.addEventListener('resize', this.onWindowResize);
    },
    onWindowResize() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onRowSelected() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.selectedRow = selectedRows.length > 0 ? selectedRows[0] : null;
      if (this.selectedRow) {
        this.$emit('row-selected', this.selectedRow);
      } else {
        this.$emit('selection-cleared');
      }
    },
    clearSelection() {
      if (this.gridApi) {
        this.gridApi.deselectAll();
        this.selectedRow = null;
      }
    },
    deleteSelected() {
      if (this.selectedRow) {
        const index = this.calculations.findIndex(item => item === this.selectedRow);
        if (index !== -1) {
          this.$emit('row-deleted', index);
        }
        this.$emit('selection-cleared');
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }
}
</script>

<style scoped>
.roe-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.grid-wrapper {
  width: 100%;
  flex-grow: 1;
}

.ag-theme-alpine {
  width: 100%;
  min-height: 200px;
  max-height: 600px;
}

.table-actions {
  margin-top: 1rem;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.delete-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.delete-btn:hover:not(:disabled) {
  background-color: #d32f2f;
}

.delete-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>