<!-- src/components/TaxDeductibleExplanation.vue -->
<template>
  <div id="description" class="tax-deductible-explanation">
    <h2>Immobilien Rentabilität steigern: Steuerlich absetzbare Kosten bei der Kapitalanlage optimal nutzen</h2>
    
    <h3>Rentabilitätsanalyse Immobilien: Welche Kosten können Sie bei Ihrer Kapitalanlage absetzen?</h3>
    <p>
      Für eine optimale Wirtschaftlichkeit Ihrer Immobilie als Investment ist die steuerliche Optimierung entscheidend. 
      Bei vermieteten Immobilien können verschiedene Kosten steuerlich geltend gemacht werden, was die Netto-Rendite 
      Ihrer Immobilie deutlich verbessert. Die wichtigsten Komponenten für die Rentabilitätsberechnung Immobilie sind 
      die Abschreibung (AfA) und die Finanzierungskosten. Eine durchdachte Strategie beim Vermögensaufbau mit Immobilien 
      berücksichtigt diese steuerlichen Aspekte von Anfang an.
    </p>

    <h3>Immobilien Investment Analyse: Ermittlung des abschreibungsfähigen Gebäudewerts</h3>
    <p>
      Für die Wirtschaftlichkeitsberechnung von Immobilien ist der abschreibungsfähige Gebäudewert ein zentraler Faktor. 
      Bei der Immobilienanalyse werden neben dem Gebäudekaufpreis auch anteilige Erwerbsnebenkosten der Abschreibung zugerechnet:
    </p>
    <ul>
      <li>Notarkosten (anteilig für Gebäudewert)</li>
      <li>Grunderwerbsteuer (anteilig für Gebäudewert)</li>
      <li>Maklerkosten (anteilig für Gebäudewert)</li>
      <li>Grundbuchkosten (anteilig für Gebäudewert)</li>
      <li>Kosten für Gutachter und Sachverständige</li>
      <li>Vermessungskosten</li>
      <li>Gerichtskosten im Zusammenhang mit dem Erwerb</li>
    </ul>
    <p>
      Für eine präzise Wirtschaftlichkeitsanalyse Immobilien erfolgt die Aufteilung dieser Kosten im gleichen Verhältnis 
      wie die Aufteilung zwischen Grund und Boden sowie Gebäudewert. Wurde das Gebäude beispielsweise mit 70% des 
      Kaufpreises bewertet, können auch 70% der Erwerbsnebenkosten dem Gebäudewert zugerechnet werden.
    </p>

    <h3>ROI Immobilien: Abschreibung (AfA) als wichtiger Rentabilitätsfaktor</h3>
    <p>
      Wenn Sie eine Wohnung als Investment kaufen, beträgt die lineare Abschreibung für Wohnimmobilien 2% des gesamten 
      Gebäudewerts pro Jahr über einen Zeitraum von 50 Jahren. Um die Rendite berechnen zu können, muss der komplette 
      Gebäudewert inklusive anteiliger Erwerbsnebenkosten berücksichtigt werden. Diese Abschreibung reduziert Ihre 
      steuerpflichtigen Einkünfte aus Vermietung und Verpachtung und ist ein wesentlicher Faktor für die Berechnung 
      der Immobilienrendite vor und nach Steuern.
    </p>

    <h3>Investment Immobilien kaufen: Erhöhung des abschreibungsfähigen Gebäudewerts</h3>
    <p>
      Beim Immobilien Investment können verschiedene Maßnahmen den abschreibungsfähigen Gebäudewert erhöhen:
    </p>
    <ul>
      <li>Modernisierungsmaßnahmen vor der ersten Vermietung</li>
      <li>Anschaffungsnahe Herstellungskosten (Renovierungen innerhalb der ersten drei Jahre)</li>
      <li>Ausbauten und Erweiterungen</li>
      <li>Grundlegende Modernisierungen der Gebäudesubstanz</li>
      <li>Nachträgliche Installations- oder Ausstattungsverbesserungen</li>
    </ul>
    <p>
      Diese Investitionen erhöhen den Gebäudewert und damit die jährliche Abschreibungsbasis. 
      Besonders bei Anlageimmobilien kaufen im Bestand kann dies zu erheblichen steuerlichen Vorteilen führen.
    </p>

    <h3>Wie berechne ich die Rendite einer Immobilie: Finanzierungskosten als Werbungskosten</h3>
    <p>
      Für die Rentabilitätsrechnung Immobilie spielen die Finanzierungskosten eine wichtige Rolle. 
      Die Darlehenszinsen für Ihre Immobilienfinanzierung sind vollständig als Werbungskosten absetzbar. 
      Dies gilt sowohl für die Kaufpreisfinanzierung als auch für Modernisierungsdarlehen. Die Tilgung hingegen 
      stellt keine Kosten dar, sondern Vermögensaufbau mit Immobilien, weshalb sie steuerlich nicht berücksichtigt wird.
    </p>

    <h3>Die Berechnung der steuerlich absetzbaren Kosten im Detail</h3>
    <p>
      Die Formel für die jährlichen steuerlich absetzbaren Kosten lautet:
    </p>
    <p class="formula">
      <strong>Absetzbare Kosten = (Gebäudewert + Anteilige Nebenkosten + Modernisierungen) × 0,02 (AfA) + Jährliche Darlehenszinsen + Jährliche Betriebskosten</strong>
    </p>

    <h3>Weitere absetzbare Kosten für maximale Steuerersparnis</h3>
    <p>
      Neben AfA und Finanzierungskosten können Sie folgende Ausgaben direkt steuerlich geltend machen:
    </p>
    <ul>
      <li>Laufende Instandhaltungskosten</li>
      <li>Hausverwaltung und Hausmeisterservice</li>
      <li>Versicherungsbeiträge für die Immobilie</li>
      <li>Grundsteuer und Straßenreinigungsgebühren</li>
      <li>Fahrtkosten zur Immobilie (0,30€ pro Kilometer)</li>
      <li>Kosten für Steuerberater und rechtliche Beratung</li>
      <li>Kontoführungsgebühren für das Mietkonto</li>
      <li>Telefonkosten im Zusammenhang mit der Vermietung</li>
      <li>Fachliteratur und Fortbildungen zum Thema Vermietung</li>
    </ul>

    <h3>Sonderfall: Anschaffungsnahe Herstellungskosten</h3>
    <p>
      Renovierungskosten innerhalb der ersten drei Jahre nach Anschaffung, die 15% der Anschaffungskosten übersteigen, 
      gelten als anschaffungsnahe Herstellungskosten. Diese können nicht sofort abgesetzt, 
      sondern müssen über die AfA-Dauer von 50 Jahren abgeschrieben werden. 
      Die 15%-Grenze bezieht sich dabei auf den reinen Gebäudewert ohne Grundstücksanteil.
    </p>

    <h3>Steuerliche Gestaltungsmöglichkeiten clever nutzen</h3>
    <p>
      Eine durchdachte Steuerstrategie kann die Rendite Ihrer Immobilien-Investition erheblich steigern. 
      Durch die optimale Nutzung aller Abschreibungsmöglichkeiten und die geschickte Verteilung von Renovierungskosten 
      lässt sich die Steuerlast systematisch reduzieren. Besonders in den ersten Jahren der Vermietung, 
      wenn die Zinslast noch hoch ist, ergeben sich hier attraktive Gestaltungsmöglichkeiten.
    </p>

    <h3>Professionelle Steuerplanung als Schlüssel zum Erfolg</h3>
    <p>
      Die steuerlichen Aspekte sind ein entscheidender Faktor für den Erfolg Ihres Immobilien-Investments. 
      Eine vorausschauende Planung und die Kenntnis aller Absetzmöglichkeiten helfen Ihnen, 
      die Rentabilität Ihrer Immobilie zu optimieren. Die Kombination aus AfA, Finanzierungskosten 
      und weiteren Werbungskosten bietet erhebliches Potenzial zur Steueroptimierung. 
      Lassen Sie sich von einem Steuerberater beraten, um alle Möglichkeiten optimal zu nutzen 
      und Ihre Immobilien-Investition zum Erfolg zu führen.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TaxDeductibleExplanation',
  metaInfo: {
    title: 'Immobilien Rentabilität & Rendite berechnen | Steuerliche Optimierung',
    meta: [
      { name: 'description', content: 'Rentabilität Immobilie berechnen ✓ Steuervorteile optimal nutzen ✓ Kostenloser Renditerechner Immobilien ➤ Jetzt Immobilienrendite vor und nach Steuern ermitteln!' }
    ]
  }
}
</script>

<style scoped>
.tax-deductible-explanation {
  line-height: 1.6;
}

h2, h3 {
  color: #2c3e50;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
}

p {
  margin-bottom: 1em;
}

.formula {
  font-family: 'Roboto', serif;
  font-size: 1.2em;
  text-align: center;
  margin: 1em 0;
}

strong {
  font-weight: 600;
}

ul {
  margin-bottom: 1em;
  padding-left: 2em;
}

@media (max-width: 768px) {
  .tax-deductible-explanation {
    padding: 0.5rem;
  }
  
  h2 {
    font-size: 1.75em;
  }
  
  h3 {
    font-size: 1.25em;
  }
}
</style>