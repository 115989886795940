<template>
  <div id="app">
    <AppHeader />
    <div id="app-body">
      <router-view></router-view>
    </div>
    <CookieBanner @preferences-updated="handlePreferencesUpdate" />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import CookieBanner from './components/CookieBanner.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    CookieBanner,
    AppFooter
  },
  methods: {
    handlePreferencesUpdate(preferences) {
      // Handle the updated preferences here
      console.log('Cookie preferences updated:', preferences);
      // You can use this to enable/disable optional features based on user consent
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app-body {
  flex: 1;
  margin: 20px auto;
  max-width: 1100px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  #app-body {
    padding: 0 10px;
    margin: 10px auto;
  }
}
</style>
